import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {

    const navigate = useNavigate();

    useEffect(() => {
        redirect();
    });

    const redirect = () => {
        navigate('/aviso');
    }

    return (
        <div>not found</div>
    );
}