import React from "react";

export default function UnavailablePage() {
    return (
        <section className=" d-flex  align-items-center" id="notWorking">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="contact-wrap contact-pages mb-0">
                            <div className="contact-form">
                                <div className=" row d-flex justify-content-center ">
                                    <h2 style={{ fontSize: "2.5rem" }}>
                                        Lo sentimos
                                    </h2>

                                </div>
                                <div className="row d-flex justify-content-center">
                                    <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
                                        Estamos trabajando en mejorar nuestro servicio!
                                    </p>
                                </div>
                                <div className="row d-flex justify-content-center p-3" style={{ fontSize: "10rem", color: "orange" }}>
                                    <i className='bx bxs-error'></i>
                                </div>
                                <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
                                    Lamentamos los inconvenientes que esto pueda causar :(
                                </p>
                                <div className="row d-flex justify-content-center p-3" style={{ fontSize: "10rem", color: "orange" }}>
                                    {/* <img src="/assets/images/Logo.webp"></img> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}